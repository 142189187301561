export default {
    data() {
        return {
            handler: null,
            oldData: [],
        }
    },
    methods: {
        addHandler(data) {
            if (_.isEqual(this.oldData, data)) {
                this.handler = null
            } else {
                this.handler = true
            }
        },
    },
}

<template>
  <div>
    <KTCard>
      <template v-slot:title>
        {{ operationTitle }}
        <span class="d-block text-muted pt-2 font-size-sm">View, Add, Edit or Delete Quotations</span>
      </template>
      <template v-slot:toolbar>
        <b-input-group-append>
          <b-button
            :class="checkFilterCount ? `btn btn-danger text-white font-weight-bolder ml-2` : 'btn btn-light-primary font-weight-bolder ml-2'"
            v-b-toggle.sidebar-backdrop-quotations>
            <i class="fa fa-filter fa-sm"></i> Filter
          </b-button>

        </b-input-group-append>
      </template>
      <template v-slot:body>
        <v-card>
          <div class="w-100 mb-6">
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length"><label>Show
                  <b-form-select :options="[10, 50, 100]"
                                 class="datatable-select"
                                 size="sm"
                                 v-model="pagination.totalPerPage"></b-form-select>
                  entries</label></div>

              </div>

              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter" id="kt_datatable_filter"><label>Search:
                  <b-form-input class="form-control form-control-sm ml-2 w-25"
                                label="Search"
                                type="search"
                                v-model="search"
                                v-on:keyup.enter="handleSearch"></b-form-input>
                </label>
                </div>
              </div>
            </div>
          </div>
          <div class="quotations-table"
               v-if="$global.hasPermission('quotationsview') && operation === null">

            <v-data-table
              :headers="columns"
              :items="dataSource"
              :items-per-page="parseInt(pagination.totalPerPage)"
              :loading="loading"
              class="table table-head-custom table-vertical-center table-responsive"
              ref="table"
              responsive

            >
              <template v-slot:item.id="record">
                {{ ( pagination.current - 1 ) * pagination.totalPerPage + ( record.index + 1 ) }}
              </template>
              <template v-slot:item.lead="{item}">
                {{ item.lead ? item.lead.label : "" }}
              </template>
              <template v-slot:item.event="{item}">
                {{ item.event ? item.event.event_type : "" }}<br />
              </template>
              <template v-slot:item.total="{item}">
                {{ ( item.total && item.total.total > 0 ) ? $global.numberToLocationString(item.total.total) : 0 }}
              </template>
              <template v-slot:item.updated_at="{item}">
                {{ item.updated_at ? $global.dateFormat(item.updated_at) : "" }}
              </template>
              <template class="action-column" v-slot:item.action="record">
                <b-dropdown no-caret size="sm" toggle-class="text-decoration-none" variant="link">
                  <template #button-content>
                    <p class="btn btn-icon btn-light btn-hover-primary btn-sm">
                      <span class="svg-icon svg-icon-md svg-icon-primary m-0">
                      <inline-svg class="action-edit-button" src="/media/svg/icons/General/Settings-1.svg"></inline-svg>
                        <!--end::Svg Icon-->
                    </span>
                    </p>
                  </template>
                  <b-dropdown-item @click="redirectToRouteLink(`${type}/edit/${record.item.id}`)"
                                   v-show="$global.hasPermission('quotationsupdate')">
                                                    <span
                                                      class="pt-1 pb-1"
                                                      size="sm">
                                                        <i class="fa fa-edit mr-2"></i> Edit
                                                </span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="handleDeleteOperation(record.item.id)"
                                   v-if="$global.hasPermission('quotationsdelete') && !record.item.book">
                                                <span class="pt-1 pb-1" size="sm">
                                                    <i class="fa fa-share mr-2"></i> Delete
                                                </span>
                  </b-dropdown-item>
                </b-dropdown>
                <button @click="downloadPdf(record.item.id)"
                        class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2">
                      <span aria-label="Share" class="svg-icon svg-icon-md svg-icon-primary m-0">
                        <inline-svg aria-label="Share" class="action-edit-button" src="/media/svg/files/pdf.svg" />
                      </span>
                </button>
                <button @click="downloadPdf(record.item.id, 'send')"
                        class="btn btn-icon btn-light btn-hover-primary btn-sm ml-2">
                      <span aria-label="Share" class="svg-icon svg-icon-md svg-icon-primary m-0">
                        <inline-svg aria-label="Share" class="action-edit-button" src="/media/svg/icons/Communication/whatsapp.svg" />
                      </span>
                </button>
              </template>
            </v-data-table>
            <div class="row">
              <div class="pagination-box">
                <b-pagination
                  :per-page="pagination.totalPerPage"
                  :total-rows="pagination.total"
                  size="lg"
                  v-model="pagination.current"
                ></b-pagination>

              </div>
            </div><!-- /.pull-right -->
          </div>
        </v-card>
      </template>
    </KTCard>

    <template>
      <div>
        <b-sidebar
          :backdrop-variant="`transparent`"
          backdrop
          id="sidebar-backdrop-quotations"
          right
          shadow
          title="Filters"
        >
          <template #header="{ hide }">
            <b-col sm="10">
              <strong id="sidebar-backdrop-leads___title__">Filters</strong>
            </b-col>
            <b-col sm="2">
              <button @click="hide();" class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
                <i class="ki ki-close icon-xs text-muted"></i>
              </button>
            </b-col>
          </template>
          <template #footer="{ hide }">
            <div
              class="d-flex justify-center cursor-pointer w-full border-top align-items-center px-3 py-4">
              <b-button @click="hide();handleResetFilterClick()"
                        class="btn btn-light-primary font-weight-bolder ml-2">
                <i class="fa fa-broom fa-sm"></i> Clear All
              </b-button>
            </div>
          </template>
          <div class="px-10 py-4">
            <b-col sm="12">
              <b-form-group
                label="Start Date"
                label-for="from_date">
                <b-form-datepicker
                  :date-disabled-fn="dateDisabled"
                  :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                  close-button
                  reset-button
                  today-button
                  v-model="filters.from_date"
                >
                </b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="End Date"
                label-for="to_date">
                <b-form-datepicker
                  :date-disabled-fn="dateDisabled"
                  :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                  :disabled="!filters.from_date"
                  close-button
                  reset-button
                  today-button
                >
                </b-form-datepicker>
              </b-form-group>
            </b-col>
          </div>
        </b-sidebar>
      </div>
    </template>
    <b-modal hide-footer id="quotation-event-details" size="xl" title="Quotation Event Details">
      <b-row>
        <b-col :key="index" sm="4" v-for="(data,index) in quotationEventDetail">
          <b-card
            align="center"
            border-variant="primary"
          >
            <b-card-header class="bg-primary text-white p-2">
              {{ data.event_type }}
            </b-card-header>
            <b-card-text class="p-10">
              <p class="text--darken-2 mb-2">{{ $global.dateFormat(data.from_date) }} -
                ({{ data.from_time }} - {{ data.to_time }})</p>
              <b-tag :key="index" v-for="(d,index) in data.halls">
                {{ d.name }}
              </b-tag>
              <p class="text--darken-2 mb-2 m-2">
                <strong>{{ data.total ? Number(data.total.total).toLocaleString() : 0 }}</strong>
              </p>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col v-if="!quotationEventDetail.length">
          <h4>No data available</h4>
        </b-col>
      </b-row>
    </b-modal>

    <div id="containingDiv"></div>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import KTCard from "@/view/content/Card.vue";
import ListingMixin from "@/assets/js/components/ListingMixin";
import handleMatchDataMixin from "./handleMatchDataMixin";
import { bus } from "@/main";
import totalAmountMixin from "../../../assets/js/components/totalAmountMixin";
import handleError from "@/util/handleError";
import ModuleMixing from "./module.mixin";

const DEFAULT_FILTER_STATE = {
  from_date: null,
  to_date: null,
};

export default {
  mixins: [ListingMixin, handleMatchDataMixin, totalAmountMixin, handleError, ModuleMixing],
  data() {
    return {
      filters: { ...DEFAULT_FILTER_STATE },
    };
  },
  components: {
    KTCard,
    // PSelect,
  },
  beforeDestroy() {
    bus.$off('change-event');
    bus.$off('quotation-data-refresh');
  },
  methods: {
    handleResetFilterClick() {
      this.filters = { ...DEFAULT_FILTER_STATE };
      this.isFilterApplied = 'reset';
      this.loadList(this.listQueryParams);
    }
  },
  computed: {
    ...mapState([
      'global',
    ]),
    ...mapGetters({
      checkPendingEventData: 'checkPendingEventData',
      checkPendingRoomData: 'checkPendingRoomData',
    }),
  },
};
</script>
